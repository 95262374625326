import React, { useState } from "react";
import { Input, Card, message, Button, Divider, Row, Col } from "antd-min";
import { withRouter } from "gl-commonui";
import { Container, Loading } from "@app/components";
import { useService } from "@app/hooks";
import { TYPES, InvitationType, DateHelper, fmtMsg } from "@app/util";
import { IInvitationService, InvitationCodeLookupResponseModel, InvitationCodeStatusCode, InvitationStatus } from "@app/service/admin/acceptinvitation";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import "./code-lookup.less";

export const CodeLookup = () => {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [code, setCode] = useState("");
    const [invitationCode, setInvitationCode] = useState({} as InvitationCodeLookupResponseModel);
    const service: IInvitationService = useService<IInvitationService>(TYPES.IInvitationService);

    const onSearch = () => {
        if (!code) {
            setIsError(true);
            return;
        }

        setLoading(true);
        service.lookupInvitationCode(code.trim())
            .then(codeResponse => {
                setLoading(false);
                setIsError(codeResponse.statusCode !== InvitationCodeStatusCode.Success);
                setInvitationCode(codeResponse);
            })
            .catch(() => {
                message.error(fmtMsg({ id: GSAdminLocale.CodeLookupError }));
            });
    }

    const onInputChange = ({ target: { value } }) => {
        setCode(value);
        setIsError(!value);
    }

    const getNormalizedInvitationType = (invitationType: InvitationType) => {
        if (invitationType === undefined || invitationType === null ) {
            return;
        }

        switch (invitationType.toString()) {
            case InvitationType.ClassTeacher:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryTypeTeacher });
            case InvitationType.Parent:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryTypeParent });
            case InvitationType.CampusAdmin:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryTypeCampus });
            case InvitationType.SchoolAdmin:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryTypeSchool });
            case InvitationType.RegionAdmin:
                return fmtMsg({ id: GSAdminLocale.CodeLookupRegionAdmin });
            case InvitationType.TrainingAdmin:
                return fmtMsg({ id: GSAdminLocale.CodeLookupTrainingAdmin });
            case InvitationType.Trainer:
                return fmtMsg({ id: GSAdminLocale.CodeLookupTrainer });
            case InvitationType.RegionTrainer:
                return fmtMsg({ id: GSAdminLocale.CodeLookupTrainer });
            case InvitationType.GlobalHead:
                return fmtMsg({ id: GSAdminLocale.CodeLookupGlobalHead });
            case InvitationType.SystemAdmin:
                return fmtMsg({ id: GSAdminLocale.CodeLookupSystemAdmin });
            case InvitationType.SubTeacher:
                return fmtMsg({ id: GSAdminLocale.CodeLookupSubstituteTeacher });
            case InvitationType.SchoolTeacher:
                return fmtMsg({ id: GSAdminLocale.CodeLookupSchoolTeacher })
            case InvitationType.AccountManager:
                return fmtMsg({ id: GSAdminLocale.CodeLookupAccountManager })
            case InvitationType.ContentAdmin:
                return fmtMsg({ id: GSAdminLocale.CodeLookupContentAdmin });
            default:
                break;
        }
    }

    const getInvitationStatus = (invitationStatus: InvitationStatus): string => {
        switch (invitationStatus) {
            case InvitationStatus.Init:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryStatusPending });
            case InvitationStatus.Activated:
            case InvitationStatus.ChildrenAdded:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryStatusAccepted });
            case InvitationStatus.Expire:
                return fmtMsg({ id: SchoolLocale.InvitationSchoolInvitationHistoryStatusExpired });

            default:
                return "";
        }
    }

    const getErrorMessage = (statusCode: InvitationCodeStatusCode) => {
        console.log('invitation code response...', invitationCode);
        if (!code) {
            return fmtMsg({ id: GSAdminLocale.CodeLookupRequired });
        }

        switch (statusCode) {
            case InvitationCodeStatusCode.DoesNotBelongToCampus:
                return fmtMsg({ id: GSAdminLocale.CodeLookupDoesNotBelongToCampus });
            case InvitationCodeStatusCode.DoesNotBelongToSchool:
                return fmtMsg({ id: GSAdminLocale.CodeLookupDoesNotBelongToSchool });
            case InvitationCodeStatusCode.DoesNotBelongToRegion:
                return fmtMsg({ id: GSAdminLocale.CodeLookupDoesNotBelongToRegion });
            case InvitationCodeStatusCode.DoesNotExists:
                return fmtMsg({ id: GSAdminLocale.CodeLookupDoesNotExists });

            default:
                return null;
        }
    }

    const renderEmail = () => {
        const isAccepted = invitationCode.invitationStatus === InvitationStatus.Activated ||
            invitationCode.invitationStatus === InvitationStatus.ChildrenAdded;

        if(!isAccepted && invitationCode.email && invitationCode.email.trim().length){
            return invitationCode.email;
        }

        if (!isAccepted || !invitationCode.email || !invitationCode.email.trim().length) {
            return "NA";
        }

        if (invitationCode.userId && invitationCode.userId.trim().length) {
            return (
                <a href={'/users/' + invitationCode.userId + '/edit'}>
                    {invitationCode.email}
                </a>
            )
        }

        return invitationCode.email;
    }
    const renderPhone = () => {
        const isAccepted = invitationCode.invitationStatus === InvitationStatus.Activated ||
            invitationCode.invitationStatus === InvitationStatus.ChildrenAdded;

        if(!isAccepted && invitationCode.phone && invitationCode.phone.trim().length){
            return invitationCode.phone;
        }

        if (!isAccepted || !invitationCode.phone || !invitationCode.phone.trim().length) {
            return "NA";
        }

        if (invitationCode.userId && invitationCode.userId.trim().length) {
            return (
                <a href={'/users/' + invitationCode.userId + '/edit'}>
                    {invitationCode.phone}
                </a>
            )
        }

        return invitationCode.phone;
    }    
    return (
        <Container className="lookup-container">
            <Row className="lookup-container-row" type="flex" justify="center">
                <h1>{fmtMsg({ id: GSAdminLocale.CodeLookupHeader })}</h1>
            </Row>
            <Row className="lookup-container-row" type="flex" justify="center" gutter={3}>
                <Col>
                    <label className="lookup-search-label">{fmtMsg({ id: GSAdminLocale.CodeLookupCodeLabel })}: </label>
                    <Input className="lookup-search-input" onChange={onInputChange} />
                    {isError ? <div className="code-error">{getErrorMessage(invitationCode.statusCode)}</div> : null}
                </Col>
                <Col>
                    <Button className="lookup-search-btn" type="primary" onClick={onSearch}>{fmtMsg({ id: GSAdminLocale.Search })}</Button>
                </Col>
            </Row>
            <Loading visible={loading}></Loading>
            {
                invitationCode.statusCode === InvitationCodeStatusCode.Success && !loading ? <Card className="lookup-card">
                    <>
                        <Row className="lookup-description-row" type="flex" justify="center">
                            <Col md={12} sm={24} xs={24}>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupInvitationType })}:{" "}
                                </span>
                                <span className="lookup-description-value">{getNormalizedInvitationType(invitationCode.invitationType)}</span>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupIssueDate })}:{" "}
                                </span>
                                <span className="lookup-description-value">{DateHelper.formatDate2Local(invitationCode.issueDate)}</span>
                            </Col>
                        </Row>
                        <Row className="lookup-description-row" type="flex" justify="center">
                            <Col md={12} sm={24} xs={24}>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupInvitationStatus })}:{" "}
                                </span>
                                <span className="lookup-description-value">{getInvitationStatus(invitationCode.invitationStatus)}</span>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupRegistrationDate })}:{" "}
                                </span>
                                <span className="lookup-description-value">{DateHelper.formatDate2Local(invitationCode.registrationDate)}</span>
                            </Col>
                        </Row>
                        <Row className="lookup-description-row" type="flex">
                            <Col md={12} sm={24} xs={24}>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.InvitationModalEmail })}:{" "}

                                </span>
                                <span className="lookup-description-value">
                                    {renderEmail()}
                                </span>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.InvitationModalPhone })}:{" "}
                                </span>
                                <span className="lookup-description-value">
                                    {renderPhone()}
                                </span>
                            </Col>
                        </Row>
                        <Divider />
                        <Row className="lookup-from" type="flex" justify="start">
                            <Col>{fmtMsg({ id: GSAdminLocale.CodeLookupIssueFrom })}</Col>
                        </Row>
                        <Row className="lookup-description-row" type="flex" justify="start">
                            <Col>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupRegion })}:{" "}
                                </span>
                                <span className="lookup-description-value">{invitationCode.region ? invitationCode.region.name : ""}</span>
                            </Col>
                        </Row>
                        <Row className="lookup-description-row" type="flex" justify="start">
                            <Col>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupSchool })}:{" "}
                                </span>
                                <span className="lookup-description-value">{invitationCode.school ? invitationCode.school.name : ""}</span>
                            </Col>
                        </Row>
                        <Row className="lookup-description-row" type="flex" justify="start">
                            <Col>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupCampus })}:{" "}
                                </span>
                                <span className="lookup-description-value">{invitationCode.campus ? invitationCode.campus.name : ""}</span>
                            </Col>
                        </Row>
                        <Row className="lookup-description-row" type="flex" justify="start">
                            <Col>
                                <span className="lookup-description-label">
                                    {fmtMsg({ id: GSAdminLocale.CodeLookupClass })}:{" "}
                                </span>
                                <span className="lookup-description-value">{invitationCode.schoolClass ? invitationCode.schoolClass.name : ""}</span>
                            </Col>
                        </Row>
                    </>
                </Card> : null
            }
        </Container>
    );
};

export const CodeLookupPage = withRouter(CodeLookup);
